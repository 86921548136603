import { HOST_TYPES } from 'constants/constants';
import * as environment from 'utils/environment';

// AWS Amplify credentials
const AWS_AMPLIFY_DEFAULTS = {
  REGION: 'eu-west-1',
  USER_POOL_ID: 'eu-west-1_R60WRWhpr',
  WEB_CLIENT_ID_STAGE: '24p0gdhog2d6vq6sc5abnf928c', // Echobox-UI-Login-Staging
  WEB_CLIENT_ID_LIVE: '7cdhie3ij1lt73q111nljqfud6', // Echobox-UI-Login
  AUTHENTICATION_FLOW_TYPE: 'USER_PASSWORD_AUTH',
};

const hostType = environment.getHostType();
const isSafari = environment.isSafari();

export const AWS_AMPLIFY_CONFIG = {
  Auth: {
    region: AWS_AMPLIFY_DEFAULTS.REGION,
    userPoolId: AWS_AMPLIFY_DEFAULTS.USER_POOL_ID,
    userPoolWebClientId:
      hostType === HOST_TYPES.PROD
        ? AWS_AMPLIFY_DEFAULTS.WEB_CLIENT_ID_LIVE
        : AWS_AMPLIFY_DEFAULTS.WEB_CLIENT_ID_STAGE,
    authenticationFlowType: AWS_AMPLIFY_DEFAULTS.AUTHENTICATION_FLOW_TYPE,
    ...(isSafari && {
      cookieStorage: {
        domain: window.location.hostname,
        path: '/',
        expires: 1,
        secure: true,
        sameSite: 'none',
      },
    }),
  },
};

export const COGNITO_CHALLENGES = {
  NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
  SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
};
